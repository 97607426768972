import { BlogCard } from '@/components/blog/components'
import useStore from '@/hooks/useStore'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'

const Home = () => {
  const data = useStore((state) => state.home?.blog) as HomeBlog
  const { push } = useRouter()
  return (
    <Container sx={{ py: 10 }}>
      <Stack
        justifyContent="center"
        sx={{ width: { xs: 1, md: 0.5 }, mx: 'auto' }}
      >
        <Typography
          textAlign="center"
          fontWeight={700}
          variant="h4"
          gutterBottom
        >
          {data?.title}
        </Typography>
        <Typography textAlign="center" color="text.secondary" gutterBottom>
          {data?.description}
        </Typography>
      </Stack>
      <Grid container spacing={4} sx={{ my: 2 }}>
        {data?.articles?.map((el) => (
          <Grid item xs={12} sm={6} md={4} key={el?.id}>
            <BlogCard data={el} />
          </Grid>
        ))}
      </Grid>
      <Stack alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          endIcon={<ArrowForwardIcon />}
          onClick={() => push(data?.button?.path)}
          sx={{ px: 6, py: 2, borderRadius: 50, mt: 4 }}
        >
          {data?.button?.label}
        </Button>
      </Stack>
    </Container>
  )
}

export default Home
